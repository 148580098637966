import { Component, OnInit } from '@angular/core';
import { AppURL } from 'src/app/app.url';
import { AuthURL } from 'src/app/cbn/cbn.url';
import { AuthenService } from 'src/app/components/_services/authen.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from 'src/app/cbn/components/profile/_services/profile.service';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-auth-sidebar',
  templateUrl: './auth-sidebar.component.html',
  styleUrls: ['./auth-sidebar.component.css']
})
export class AuthSidebarComponent implements OnInit {
  i: any;
  avatar: any;
  AppURL = AppURL;
  AuthURL = AuthURL;
  displayName: string;
  url: string;
  error: string;
  showOverlay = true
  constructor(
    private authenticationService: AuthenService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private profileService: ProfileService
  ) {
    this.url = environment.apiUrl;
    this.profileService.list$.subscribe(name => (this.displayName = name));
    this.profileService.avatar$.subscribe(avatar => (this.avatar = avatar));
  }

  ngOnInit() {
    this.getUserProfile();
  }

  getUserProfile() {
    this.profileService.getProfile()
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.avatar = response.data.avatar;
            this.displayName = response.data.username;
            this.showOverlay = false
          } else {
            this.error = response.message;
            if (this.error === 'Invalid authorization token.') {
              
              this.authenticationService.logout();
              location.href = '/login';
            }
          }
        },
        error => {
          this.error = error;
        });
  }

  logout() {
    this.toastr.success('Logout Successfully', 'Notice!');
    this.authenticationService.logout();
    location.href = '/login';
  }

  toggleSidebar() {
    // layout-expanded
    var element = document.getElementById("html");
    element.classList.remove("layout-expanded");
  }
  setActive() {
    var boxPersonal = document.getElementById('checkActive');
    var textPersonal = document.getElementById('checkActive-text');
    var boxOz = document.getElementById('oz-checkActive');
    var textOz = document.getElementById('oz-checkActive-text');
    if (this.route.snapshot.routeConfig.path === 'projects') {
      return 'active';
    } else if (this.route.snapshot.routeConfig.path === 'project-view/:id') {
      boxPersonal.classList.add('tab-active');
      textPersonal.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'projects-list') {
      boxPersonal.classList.add('tab-active');
      textPersonal.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'project-add-member/:projectId') {
      boxPersonal.classList.add('tab-active');
      textPersonal.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'project-payment-view/:id') {
      boxPersonal.classList.add('tab-active');
      textPersonal.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'user-update-address/:projectmemberId') {
      boxPersonal.classList.add('tab-active');
      textPersonal.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organizations') {
      return 'active';
    } else if (this.route.snapshot.routeConfig.path === 'organizations-create') {
      boxOz.classList.add('tab-active');
      textOz.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organizations-list') {
      boxOz.classList.add('tab-active');
      textOz.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organization-add-member/:organizeId') {
      boxOz.classList.add('tab-active');
      textOz.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organization-view/:id') {
      boxOz.classList.add('tab-active');
      textOz.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organization-project-view-payment/:id') {
      boxOz.classList.add('tab-active');
      textOz.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organization-project-view/:id/:organizeMemberId') {
      boxOz.classList.add('tab-active');
      textOz.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organization-projects-list/:organizeId/:organizeMemberId') {
      boxOz.classList.add('tab-active');
      textOz.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organization-projects-add-member/:projectId/:organizeId/:organizeMemberId') {
      boxOz.classList.add('tab-active');
      textOz.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organization-payment-create/:id/:organizeMemberId') {
      boxOz.classList.add('tab-active');
      textOz.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organization-project-view/:id') {
      boxOz.classList.add('tab-active');
      textOz.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organization-infomation/:id') {
      boxOz.classList.add('tab-active');
      textOz.classList.add('active-text');
    } else if (this.route.snapshot.routeConfig.path === 'organization-update-address/:projectmemberId/:organizationmemberId') {
      boxOz.classList.add('tab-active');
      textOz.classList.add('active-text');
    }
  }

}
