import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthNavberComponent } from './components/auth-navber/auth-navber.component';
import { AuthContentComponent } from './components/auth-content/auth-content.component';
import { AuthSidebarComponent } from './components/auth-sidebar/auth-sidebar.component';

import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AuthNavberComponent, AuthContentComponent, AuthSidebarComponent],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    AuthNavberComponent,
    AuthContentComponent,
    AuthSidebarComponent
  ]
})
export class SharedsModule { }
