import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { User } from '../../_models/users';

@Injectable({ providedIn: 'root' })
export class AuthenService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    getTerm() {
        return this.http.get(`${environment.apiUrl}/get/term/user`)
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/signin`, { username, password })
            .pipe(map((response: any) => {
                if (response.status === true) {
                    localStorage.setItem('currentUser', JSON.stringify(response));
                    localStorage.setItem('UserID', response.data.uid);
                    this.currentUserSubject.next(response);
                    return response;
                } else {
                    return response;
                }

            }));
    }

    sendMailForgotPassword(body: any) {
        return this.http.post<any>(`${environment.apiUrl}/sendmail/reset/password`, body);
    }

    changePassword(body: any, uid: string) {
        return this.http.post<any>(`${environment.apiUrl}/reset/${uid}/password`, body);
    }

    signup(body: any) {
        return this.http.post<any>(`${environment.apiUrl}/signup`, body);
    }

    logout() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('UserID');
        this.currentUserSubject.next(null);
    }
}
