import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VerifyService {

  constructor(private http: HttpClient) {
  }

  verifiyAccount(uid: string) {
    return this.http.post(`${environment.apiUrl}/verification/${uid}`, {});
  }
}
