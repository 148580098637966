import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenService } from '../../_services/authen.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error = '';
  uid: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenService,
    private toastr: ToastrService
  ) {
    this.uid = this.route.snapshot.params.uid;
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.changePasswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.authenticationService.changePassword(this.changePasswordForm.value, this.uid)
      .pipe(first())
      .subscribe(data => {
        if (data.status === true) {
          this.toastr.success(data.message, 'Notice!');
          this.router.navigate(['/login']);
        } else {
          this.toastr.error(data.message);
        }
      }, error => {
        this.error = error;
      });
  }

}
