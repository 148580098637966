import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenService } from '../_services/authen.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/cbn'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    // logged in so return true
    return true;
  }

}
