import { Component, OnInit } from '@angular/core';
import { AppURL } from '../../../app.url';

import { ToastrService } from 'ngx-toastr';
import { AuthenService } from 'src/app/components/_services/authen.service';
import { AuthURL } from 'src/app/cbn/cbn.url';
import { ProfileService } from 'src/app/cbn/components/profile/_services/profile.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-auth-navber',
  templateUrl: './auth-navber.component.html',
  styleUrls: ['./auth-navber.component.css']
})
export class AuthNavberComponent implements OnInit {

  displayName: string;
  error: string;
  i: any;
  constructor(
    private authenticationService: AuthenService,
    private toastr: ToastrService,
    private profileService: ProfileService,
    private route: ActivatedRoute,

  ) {
    this.profileService.list$.subscribe(name => (this.displayName = name));
  }

  ngOnInit() {
    this.getUserProfile()
    this.checkDashboard()
  }

  checkDashboard() {
    var element1 = document.getElementById("showLogoDashboard");
    var element2 = document.getElementById("hideLogoDashboard");
    if (this.route.snapshot.routeConfig.path === 'dashboard') {
      element1.classList.remove("hide-logo");
      element2.classList.add("hide-logo");
    } else {
      element1.classList.add("hide-logo");
      element2.classList.remove("hide-logo");
    }
  }

  getUserProfile() {
    this.profileService.getProfile()
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.displayName = response.data.name + ' ' + response.data.surname;
          } else {
            this.error = response.message;
            if (this.error === 'Invalid authorization token.') {
              
              this.authenticationService.logout();
              location.href = '/login';
            }
          }
        },
        error => {
          this.error = error;
        });
  }

  AppURL = AppURL;
  AuthURL = AuthURL;


  logout() {
    this.toastr.success('Logout Successfully', 'Notice!');
    this.authenticationService.logout();
    location.href = '/login';
  }

  toggleSidebar() {
    // layout-expanded
    var element = document.getElementById("html");
    element.classList.add("layout-transitioning");
    element.classList.add("layout-expanded");
  }
}
