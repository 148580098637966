import { Component, OnInit } from '@angular/core';
declare const App: any;
declare const SideBar: any;

@Component({
  selector: 'app-auth-content',
  templateUrl: './auth-content.component.html',
  styleUrls: ['./auth-content.component.css']
})
export class AuthContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    App.initialLoadPage();
    SideBar.initialLoadFunction();
  }
  
  toggleSidebar() {
    // layout-expanded
    var element = document.getElementById("html");
    element.classList.remove("layout-expanded");
  }
}
