import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenService } from '../../_services/authen.service';

import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-send-mail',
    templateUrl: './send-mail.component.html',
    styleUrls: ['./send-mail.component.css']
})
export class SendMailComponent implements OnInit {
    forgotForm: FormGroup;
    submitted = false;
    returnUrl: string;
    error = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenService,
        private toastr: ToastrService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.forgotForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.forgotForm.controls; }

    onloading = false
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.forgotForm.invalid) {
            return;
        }
        this.onloading = true
        // return
        this.authenticationService.sendMailForgotPassword(this.forgotForm.value)
            .pipe(first())
            .subscribe(data => {
                if (data.status === true) {
                    this.onloading = false
                    this.toastr.success('Please check your email for change password', 'Notice!');
                    this.router.navigate(['/login']);
                } else {
                    this.onloading = false
                    this.toastr.error(data.message);
                }
            }, error => {
                this.error = 'Serve error, Can\'t send email for change password';
            });
    }

}
