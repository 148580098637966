import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { VerifyService } from '../verify-account/_services/verify.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.css']
})
export class VerifyAccountComponent implements OnInit {
  uid: string;
  error: string;
  status = false;
  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private vfService: VerifyService
  ) {
    this.uid = this.route.snapshot.params.uid;
  }

  ngOnInit() {
    this.verifiedAccount();
  }

  verifiedAccount() {
    this.vfService.verifiyAccount(this.uid)
      .pipe(first())
      .subscribe((response: any) => {
        if (response.status === true) {
          this.status = response.status;
          this.toastr.success(response.message, 'Notice!');
        } else {
          this.status = response.status;
          this.toastr.error(response.message);
        }
      }, error => {
        this.error = error;
      });
  }

}
