import { Component, OnInit } from '@angular/core';
import { AppURL } from 'src/app/app.url';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'
import { AuthenService } from '../_services/authen.service';
import { MustMatch } from '../_helpers/must-match';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  signupForm: FormGroup;
  loading = false;
  disable = true;
  submitted = false;
  returnUrl: string;
  term: any;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private authenticationService: AuthenService
  ) { }

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      username: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: [''],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      agreeBox: [false, Validators.requiredTrue],
      personal_phone: ['']
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  get s() { return this.signupForm.controls; }

  getTerm() {
    this.authenticationService.getTerm()
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            this.term = response.data.content
          }
        },
        error => {
          this.error = error;
        });
  }

  agreeSubmit() {
    if (this.disable) {
      this.disable = false;
    } else {
      this.disable = true;
    }
  }
  onSubmit() {
    
    this.submitted = true;
    // stop here if form is invalid
    if (this.signupForm.invalid) {
      return;
    }
    if (this.signupForm.value.email == '' && this.signupForm.value.personal_phone  == '' ) {
      return;
    }

    this.loading = true;
    let timerInterval
    Swal.fire({
      title: 'Please wait...',
      html: 'Registering...',
      onBeforeOpen: () => {
        Swal.showLoading();
      },
      onClose: () => {
        clearInterval(timerInterval);
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer')
      }
    });
    this.authenticationService.signup(this.signupForm.value)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if (response.status === true) {
            Swal.fire(
              'Successful!',
              response.message,
              'success'
            );
            this.router.navigate(['/login']);
          } else {
            this.error = response.message;
            Swal.fire(
              'Fail!',
              response.message,
              'error'
            );
          }
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  AppURL = AppURL;

}
