import { OrganizationAddressComponent } from './components/organization/organization-address/organization-address.component';

export const AuthURL = {
    Dashboard: 'dashboard',

    Setting: 'setting',

    Profile: 'profile',
    UserAddress: 'user-update-address',

    Projects: 'projects',
    ProjectsList: 'projects-list',
    ProjectAddMember: 'project-add-member',
    ProjectView: 'project-view',
    ProjectPaymentView: 'project-payment-view',
    ProjectPaymentCreate: 'project-payment-create',
    PaymentPay: 'payment-pay',

    OrganizationProjectView: 'organization-project-view',
    OrganizationProjectViewPayment: 'organization-project-view-payment',
    Organizations: 'organizations',
    OrganizationsCreate: 'organizations-create',
    OrganizationsList: 'organizations-list',
    OrganizationProjectsList: 'organization-projects-list',
    OrganizationProjectsAddMember: 'organization-projects-add-member',
    OrganizationAddMember: 'organization-add-member',
    OrganizationView: 'organization-view',
    OrganizationPaymentCreate: 'organization-payment-create',
    OrganizationPaymentView: 'organization-payment-view',
    OrganizationAddress: 'organization-update-address',
    OrganizationInfo: 'organization-infomation'
};
